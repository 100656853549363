<template>
  <div id="imgPreview">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-header">
          <h4>{{ imgTitle }} {{ width }}x{{ height }}px {{ size | toFileSize }}</h4>
          <div class="closeImgPreview" @click="$emit('close')">
            <icon name="times" scale="2"></icon>
          </div>
        </div>
        <div class="modal-wrapper">
          <div class="modal-body-preview">
            <img :src="imgOriginal" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
//import { baseAPI } from '../../config.js';

export default {
  name: "imgPreview",
  props: ["imgTitle", "imgOriginal", "size", "width", "height"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="sass">
#imgPreview
	.modal-mask
		display: flex
		justify-content: center
		align-items: center
		position: fixed
		z-index: 10000
		top: 0
		left: 0
		width: 100%
		height: 100%
		background-color: rgba(0, 0, 0, .5)
		transition: opacity .3s ease
		overflow: auto
		.modal-header
			display: flex
			justify-content: space-between
			background: rgba(119, 119, 119, 0.8)
			align-items: center
			padding: 0.5rem 1rem
			color: #fff
			position: fixed
			top: 0
			left: 0
			right: 0
			.closeImgPreview
				color: white
				cursor: pointer
		.modal-wrapper
			transition: all .3s ease
			height: calc(100vh - 15%)
			width: calc(100vw - 15%)
			position: relative
			.modal-body-preview
				display: flex
				height: 100%
				img
					display: block
					width: 100%
					height: 100%
					object-fit: contain

	.modal-enter
		opacity: 0

	.modal-leave-active
		opacity: 0

	.modal-enter .modal-container
	.modal-leave-active .modal-container
		transform: scale(1.1)
</style>
