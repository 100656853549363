<template>
  <div id="photoBank">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">Фото банк</h1>
          <h2 class="sectionDescription">Dental photos</h2>
        </div>
        <div class="sectionInfoFilter">
          <a
            href="#"
            class="sectionInfoFilter-select"
            :class="{ active: currentSelection === '0' }"
            @click.prevent="changeFilter('0')"
          >
            <img src="./../../assets/images/photoBank/icon_photo.png" alt class="sectionInfoFilter-select-icon" />
            <span>Фото</span>
          </a>
          <a
            href="#"
            class="sectionInfoFilter-select"
            :class="{ active: currentSelection === '1' }"
            @click.prevent="changeFilter('1')"
          >
            <img src="./../../assets/images/photoBank/icon_vector.png" alt class="sectionInfoFilter-select-icon" />
            <span>Вектор</span>
          </a>
          <a
            href="#"
            class="sectionInfoFilter-select"
            :class="{ active: currentSelection === '2' }"
            @click.prevent="changeFilter('2')"
          >
            <img src="./../../assets/images/photoBank/icon_png.png" alt class="sectionInfoFilter-select-icon" />
            <span>PNG</span>
          </a>
        </div>
      </div>
    </div>
    <div class="photoBankContent">
      <div class="photoBankContentList wrapper">
        <div v-for="item in photoBankList" :key="item.id" class="photoBankItem">
          <img :src="item.preview" alt />
          <div class="cover">
            <router-link :to="{ name: 'photoBankDownload', params: { photoId: item._id } }" class="actionLink">
              <img src="./../../assets/images/downloadLink.png" alt /> Скачать
            </router-link>
            <a
              href="#"
              v-on:click.prevent.stop="
                showPreview(
                  item.title,
                  item.fileExtention === 'eps' ? item.preview : item.download,
                  item.size,
                  item.width,
                  item.height,
                )
              "
              class="actionLink"
            >
              <img src="./../../assets/images/viewLink.png" alt /> Просмотреть
            </a>
          </div>
        </div>
      </div>
      <infinite-loading @infinite="getPhotoBankList" ref="infiniteLoading">
        <span slot="no-more">Всё загружено!</span>
      </infinite-loading>
    </div>
    <imgPreview
      v-if="showImgPreview"
      @close="showImgPreview = false"
      :imgOriginal="imgPreview"
      :imgTitle="imgTitle"
      :size="imgSize"
      :width="imgWidth"
      :height="imgHeight"
    ></imgPreview>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";
import imgPreview from "./imgPreview.vue";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "PhotoBank",
  components: { mainMenu, mainFooter, InfiniteLoading, imgPreview },
  data() {
    return {
      //   page: 1,
      endpoint: "photo_bank/",
      //   currentSelection: "0",
      //   photoBankList: [],
      imgTitle: "",
      imgPreview: "",
      imgSize: 0,
      imgWidth: 0,
      imgHeight: 0,
      showImgPreview: false,
    };
  },
  computed: {
    page() {
      return this.$store.getters.getPhotoBankPage;
    },
    currentSelection() {
      return this.$store.getters.getPhotoBankType;
    },
    photoBankList() {
      return this.$store.getters.getPhotoBankList;
    },
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem("prevScroll", window.pageYOffset);
    localStorage.setItem("prevScrollPage", from.name);
    next();
  },
  methods: {
    showPreview(title, preview, size = 0, width = 0, height = 0) {
      this.imgTitle = title;
      this.imgPreview = preview;
      this.imgSize = size;
      this.imgWidth = width;
      this.imgHeight = height;
      this.showImgPreview = true;
    },
    changeFilter(type) {
      //   console.log(type);
      //   this.photoBankList = [];
      //   this.page = 1;
      this.$store.dispatch("setPhotoBankType", { type });
      console.log("refresh");
      this.$nextTick(() => {
        this.$refs.infiniteLoading.$emit("$InfiniteLoading:reset");
      });
    },
    getPhotoBankList($state) {
      var options = {
        params: {
          page: this.page,
          type: this.currentSelection,
        },
      };
      this.$http.get(this.endpoint, options).then((response) => {
        if (response.data.photoBankList.length) {
          // console.log(response.data.photoBankList)
          //   this.photoBankList = this.photoBankList.concat(
          //     response.data.photoBankList
          //   );
          //   this.page++;
          const page = this.page + 1;
          this.$store.dispatch("setPhotoBankItems", {
            page,
            list: response.data.photoBankList,
          });

          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  },
};
</script>

<style lang="sass">
#photoBank
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	.headerPreview
		display: flex
		min-height: 270px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/photoBankBackground.png')
		background-size: cover
		background-position: center center
		& .wrapper
			flex-direction: row
			align-items: center
			@media screen and (max-width: 720px)
				flex-direction: column
			& .sectionInfo
				width: fit-content
				flex-grow: 0
				@media screen and (max-width: 425px)
					width: 100%
					text-align: center
				& .sectionTitle
					font-size: 2.5rem
					color: #fff
					padding-bottom: 1.5rem
					margin: 0 0 1.5rem
					font-weight: normal
					border-bottom: 2px solid #ff0000
				& .sectionDescription
					color: #fff
					font-size: 3rem
					font-weight: normal
					@media screen and (max-width: 425px)
						font-size: 2rem
			& .sectionInfoFilter
				display: flex
				justify-content: center
				flex-grow: 1
				padding: 10px 0
				& .sectionInfoFilter-select
					display: flex
					flex-direction: column
					align-items: center
					margin: 0 30px
					@media screen and (max-width: 425px)
						margin: 0 10px
					& .sectionInfoFilter-select-icon
						display: block
						width: 80px
						height: 80px
						margin-bottom: 20px
						opacity: 0.5
						transition: all 0.3s
						@media screen and (max-width: 720px)
							width: 50px
							height: 50px
					& span
						color: #3f3d3d
						font-size: 18px
						font-weight: bold
					&:hover,
					&.active
						& .sectionInfoFilter-select-icon
							opacity: 1
	.photoBankContent
		.photoBankContentList
			display: grid
			grid-template-columns: 1fr 1fr 1fr 1fr
			@media screen and (max-width: 769px)
				grid-template-columns: 1fr 1fr 1fr
			@media screen and (max-width: 426px)
				grid-template-columns: 1fr 1fr
			@media screen and (max-width: 376px)
				grid-template-columns: 1fr
			.photoBankItem
				display: flex
				position: relative
				flex-direction: column
				> img
					display: block
					width: 100%
					height: 100%
					object-fit: cover
				&:hover
					.cover
						display: flex
				.cover
					display: none
					flex-direction: column-reverse
					position: absolute
					top: 0
					right: 0
					bottom: 0
					left: 0
					padding: 1rem
					background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(77,137,38,1) 100%)
					transition: all 0.3s ease
					.actionLink
						display: flex
						align-items: center
						color: #fff
						font-size: 1.3rem
						text-decoration: none
						> img
							margin-right: 0.5rem
						&:hover
							color: #ddd
</style>
